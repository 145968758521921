import { TicketReplyStatuses } from 'dto/ticket';
import _ from 'lodash';
export const permission2match = (permission: any[]) => {
  let res = permission.map((item) => {
    if (
      [
        'dashboard-view-active-users-chart',
        'dashboard-view-total-user-count-cards',
        'dashboard-view-users-activity-chart',
        'dashboard-view-users-matrix-chart',
      ].includes(item)
    )
      return ['/dashboard'];

    if (['view-trending-users'].includes(item)) return ['/dashboard/trending'];
    // if (
    //   [
    //     'app-user-view-app-user-profile',
    //     'app-user-delete-app-user-profile-photo',
    //     'app-user-flag-app-user-profile-photo',
    //     'app-user-delete-flag-app-user-profile-photo',
    //     'app-user-view-app-user-match-activities',
    //     'app-user-view-app-user-like-activities',
    //     'app-user-view-app-user-reported',
    //     'app-user-view-app-user-flagged-photos',
    //     'app-user-delete-app-user',
    //     'app-user-block-unblock-app-user',
    //   ].includes(item)
    // )
    //   return ['/dashboard/search-users'];
    if (['app-user-search-app-users'].includes(item))
      return ['/dashboard/search-users', '/dashboard/user-reports'];
    if (['view-all-deletion-requests'].includes(item))
      return ['/dashboard/delete-users', '/dashboard/user-reports'];
    if (['view-all-match'].includes(item))
      return ['/dashboard/match-users/', '/dashboard/match-users/list'];
    if (['dashboard-view-matches-meter-chart'].includes(item))
      return ['/dashboard/match-users/', '/dashboard/match-users/dashboard'];
    // here
    if (['view-match-summary'].includes(item))
      return ['/dashboard/match-users/', '/dashboard/match-users/summary'];
    if (['view-all-recommendations'].includes(item)) return ['/dashboard/recommended-user'];

    if (['view-all-dependent'].includes(item)) return ['/dashboard/dependents'];
    if (['user-checker-female-user-checker'].includes(item))
      return ['/dashboard/check-user', '/dashboard/female'];
    if (['user-checker-male-user-checker'].includes(item))
      return ['/dashboard/check-user', '/dashboard/male'];
    if (['user-checker-wali-user-checker'].includes(item))
      return ['/dashboard/check-user', '/dashboard/walis'];
    if (['user-bio-checker-female-user-checker'].includes(item))
      return ['/dashboard/check-user-bio', '/dashboard/check-user-bio/female'];
    if (['user-bio-checker-male-user-checker'].includes(item))
      return ['/dashboard/check-user-bio', '/dashboard/check-user-bio/male'];
    if (['subscriptions-summary'].includes(item))
      return ['/dashboard/subscription', '/dashboard/subscription/summary'];
    if (['view-subscriptions'].includes(item))
      return [
        '/dashboard/subscription',
        '/dashboard/subscription/list',
        '/dashboard/payment-refund',
      ];
    if (
      [
        'dashboard-view-total-subscribers-chart',
        'dashboard-view-subscriber-types-chart',
        'dashboard-view-subscriber-package-chart',
      ].includes(item)
    )
      return ['/dashboard/subscription', '/dashboard/subscription/dashboard'];
    if (['view-wali-payments'].includes(item)) return ['/dashboard/wali-payments'];
    if (['view-user-coins'].includes(item)) return ['/dashboard/user-coins'];
    
    if (['vidnik-landing-view-subscriptions'].includes(item)) {
      return ['/dashboard/vidnik-subscriptions'];
    }
    if (['view-all-selfie-photos'].includes(item))
      return [
        '/dashboard/selfie',
        '/dashboard/selfie/all',
        '/dashboard/selfie/verified',
        '/dashboard/selfie/rejected',
        '/dashboard/selfie/waiting',
      ];

    if (
      [
        'chat-view-rooms',
        'chat-view-room-messages',
        'chat-send-message-to-room',
        'chat-send-message-to-default-room',
      ].includes(item)
    )
      return ['/dashboard/messages', '/dashboard/messages/:id'];

    if (['app-user-view-flagged-photos'].includes(item)) return ['/dashboard/all-flagged-photo'];
    if (['app-user-view-reports'].includes(item)) return ['/dashboard/all-reported-users'];
    if (
      [
        'app-notification-view-app-notifications',
        'app-notification-create-app-notification',
        'app-notification-view-app-notification-details',
        'app-notification-edit-app-notification',
        'app-notification-delete-app-notification',
      ].includes(item)
    )
      return [
        '/dashboard/app-notification/create-notifications',
        '/dashboard/app-notification/edit-notifications/:id',
        '/dashboard/app-notifications',
      ];
    if (
      [
        'app-email-view-app-emails',
        'app-email-create-app-email',
        'app-email-view-app-email-details',
        'app-email-edit-app-email',
        'app-email-delete-app-email',
      ].includes(item)
    )
      return [
        '/dashboard/send-email',
        '/dashboard/send-email/send-new-email',
        '/dashboard/edit-email/:id',
      ];
    if (
      [
        'setting-view-contact-information',
        'setting-edit-contact-information',
        'setting-view-terms-condition',
        'setting-edit-terms-condition',
        'setting-view-privacy-policy',
        'setting-edit-privacy-policy',
      ].includes(item)
    )
      return ['/dashboard/app-settings'];
    if (
      [
        'email-template-view-welcome-mail',
        'email-template-edit-welcome-mail',
        'email-template-view-reset-password-mail',
        'email-template-edit-reset-password-mail',
        'email-template-view-subscription-mail',
        'email-template-edit-subscription-mail',
        'email-template-view-match-mail',
        'email-template-edit-match-mail',
        'email-template-view-like-mail',
        'email-template-edit-like-mail',
      ].includes(item)
    )
      return ['/dashboard/email-templated'];

    if (
      [
        'customer-service-view-tickets',
        'customer-service-reply-on-ticket',
        'customer-service-set-ticket-priority',
        'customer-service-view-ticket-details',
        'customer-service-view-ticket-messages',
        'customer-service-create-ticket',
        'customer-service-change-ticket-status',
      ].includes(item)
    )
      return [
        '/dashboard/support-system',
        '/dashboard/support-system/all-tickets',
        '/dashboard/support-system/my-tickets/open',
        '/dashboard/support-system/my-tickets/in_progress',
        '/dashboard/support-system/my-tickets/closed',
        '/dashboard/support-system/my-tickets/all',
        `/dashboard/support-system/my-tickets/${TicketReplyStatuses.WAITING_REPLY}`,
      ];

    if (['view-deactivate-requests'].includes(item))
      return [
        '/dashboard/deactivate-requests',
        '/dashboard/deactivate-requests/list',
        '/dashboard/deactivate-requests/summary',
      ];

    if (
      [
        'cs-dashboard',
        'cs-dashboard-view-tickets-comparison-cards',
        'cs-dashboard-view-tickets-overview-chart',
        'cs-dashboard-view-tickets-statistics-chart',
      ].includes(item)
    )
      return ['/dashboard/support-system/dashboard'];

    if (['user-coins-summary'].includes(item))
      return ['/dashboard/user-coins', '/dashboard/user-coins/summary'];
    if (['view-user-coins'].includes(item))
      return ['/dashboard/user-coins', '/dashboard/user-coins/list'];

    if (['vidnik-landing-view-subscriptions'].includes(item)) {
      return ['/dashboard/vidnik-subscriptions'];
    }
  });

  res = res.filter(Boolean);
  let flatArray = res.reduce((acc: any, curVal: any) => {
    return acc.concat(curVal);
  }, []);

  return _.uniq(flatArray);
};
